.absolute {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -2;
    background-color: rgba(0, 0, 0, 0.178);
    opacity: 0;
    transition: .5s;
}

.visible {
    opacity: 1;
    z-index: 999;
}

.confirmation {
    width: 500px;
    background-color: #ffffff;
    box-shadow: 0 0 100vh #2b2d2f;
}

.textBlock {
    padding: 20px;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.textBlock span {
    font-style: italic;
    color: #a09f9f;
    display: block;
    overflow-wrap: anywhere;
}

.buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    gap: 1rem;
}