.wrapper {

  max-width: 1140px;
  margin: 0 auto;
  padding: 3rem 2rem 0;
  position: relative;

  > h1 {
    text-align: center;
    margin-top: 10rem;
  }

  > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;

    > div:first-child {
      position: absolute;
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  > div {
    margin-top: 2rem;

    > b {
      font-size: 1.2em;
    }

    > span {
      margin-left: .5rem;
      font-size: 1.25rem;
      text-transform: lowercase;
      font-style: italic;
      
      * {
        font-weight: 400;
      }
    }

    p {
      display: inline;
    }
  }

  > h3 {
    text-align: center;
    margin: 2rem 0;
  }

}

.wrongQuestionsBlock {
  margin-top: 1rem;

  > h2 {
    text-align: center;
    cursor: pointer;
    > svg {
      transition: .5s;
      margin-bottom: -.5rem;
      margin-left: 1rem;
    }
  }

  > ul {
    transition: .2s;
    list-style: none;
    overflow: auto;

    li {

      padding-top: 1rem;
  
      > p {
        font-weight: 600;
      }

      > div:nth-child(3) {
        padding: 1rem;
        background-color: #b5daf7;
      }
    }
  }
}