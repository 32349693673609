* {
  font-family: "Montserrat";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-justify {
  text-align: justify !important;
}