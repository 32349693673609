
.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: .5rem 1rem;
    
}

.placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; 
    margin-bottom: .1rem;
}

.input {
    /* min-width: 300px; */
    border: 1px solid grey;
    padding: .5rem 1rem;
    outline: none;
    width: 100%;
    max-width: 100%;
    display: block;
    font-size: 1rem;
    position: relative;
    margin: 0;
}

.error {
    color: red;
    margin: 0 0 0 auto;
    font-size: .9rem;
    text-align: left;   
}