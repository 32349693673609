

.wrapper {

  max-width: 1140px;
  margin: 0 auto;
  padding: 3rem 2rem 0;

  > .titleBlock {
    position: relative;
    width: fit-content;
    margin: 0 auto;


    > svg {
      position: absolute;
      bottom: 0;
      left: calc(100% + 1rem);
      cursor: pointer;
      transition: .2s;
      color: #717171;

      &:hover {
        transform: scale(1.1);
      }
    }
  }


  .infoBlock {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    > p {
      font-weight: 800;
    }
    
    > span {
      margin-left: .5rem;
      font-size: 1.25rem;
    }
  }
  
  > p {
    margin-top: 2rem;
    font-weight: 800;
  }

  > ol {
    margin-top: 1rem;
    
    > li {
      font-size: x-large;
      padding: .5rem;
      cursor: pointer;
      color: #3497ab;

      > div {
        display: flex;
        
        > p {
          flex-basis: 95%;
          > span {
            color: #616161;
            margin-left: .5rem;
          }
        }
        
        > svg {
          margin: auto;
          opacity: 0;
          transition: .5s;
          
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      
      &:hover {
        background-color: #c0c0c01c;
        
        svg {
          opacity: 1;
        }
      }
    }
  }

  .wrapFooterBlock {

    display: flex;
    justify-content: space-between;
    align-items: center;

    > button {
      background-color: black;
      padding: 1rem 2rem;
      color: white;
      font-size: x-large;
      text-transform: uppercase;
      outline: none;
      border: none;
      cursor: pointer;
      margin-top: 1rem;
      
      &:hover {
        color: #a8a8a8;
      }
    }
  }
}

.modal {
  min-width: 1000px;
  position: relative;

  > h1 {
    text-align: center;
  }

  > h3, h5 {
    margin-top: 1rem;
  }

  > table {
    width: 100%;
    margin-top: 1rem;

    > thead {
      font-weight: 700;
    }

    .singleQBody {
      td {
        padding: .25rem 0;
        &:nth-child(1) {
          width: 30px;
        }
        // &:nth-child(3) {
        //   width: 70px;
        // }
        &:nth-child(3) {
          width: 80px;
          text-align: center;
          cursor: pointer;
          
          svg {
            margin-top: .1rem;
          }
        }
        &:nth-child(4) {
          width: 40px;
          text-align: center;
          svg {
            cursor: pointer;
          }
        }
      }
    }
    
    .multyQBody {
      td {
        padding: .25rem 0;
        &:nth-child(1) {
          width: 30px;
        }
        &:nth-child(3) {
          width: 70px;
        }
        &:nth-child(4) {
          width: 80px;
          text-align: center;
          cursor: pointer;
          
          svg {
            margin-top: .1rem;
          }
        }
        &:nth-child(5) {
          width: 40px;
          text-align: center;
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.typesBlock {
  > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.buttonsBlock {
  display: flex;
  justify-content: center;
}

.textarea {
  width: 70% !important;
  
  textarea {
    height: 10rem;
    resize: none;
  }
}

.input {
  margin: 0;
}

.greenBorder {
  input {
    border-color: green;
  }
} 