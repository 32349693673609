.timer {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    width: fit-content;
    /* padding: 1vw 2vw; */
    font-size: 2rem;
    user-select: none;
}

.title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 530px) {
    .title {
        font-size: 15px;
    }
}

