

.wrapper {

  max-width: 1140px;
  margin: 0 auto;
  padding: 3rem 2rem 0;


  > p {
    text-transform: lowercase;
    font-style: italic;
    text-align: center;
  }

  > div {
    margin-top: 2rem;

    > b {
      color: #757575;
      font-size: 1.2em;
    }

    > span {
      margin-left: .5rem;
      font-size: 1.25rem;
      text-transform: lowercase;
      font-style: italic;
    }
  }

  > button {
    background-color: black;
    padding: 1rem 2rem;
    color: white;
    font-size: x-large;
    text-transform: uppercase;
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    margin-top: 3rem;
    float: right;
    
    &:hover, &[disabled] {
      color: #a8a8a8;
    }
  }
}

.modalWrap {

  width: 100%;
  max-width: 1000px !important;
  margin: 0 !important;

  .modal {
    
    display: flex;
    align-items: center;

    > svg {
      font-size: 26px;
      cursor: pointer;
      
      &:hover {
        transform: scale(1.1);
        transition: .2s;
      }

      &[disabled] {
        color: #bbbaba;
        pointer-events: none;

        &:hover {
          transform: none;
        }
      }
    }
    
    > div {
      flex-basis: 100%;
      padding: 0 1rem;
      position: relative;

      .timerBlock {
        position: absolute;
        top: -10px;
        right: -26px;
      }

      > h5 {
        text-align: center;
        margin: 1.25rem 0;
        font-size: 1.15rem;
        user-select: none;
      }
    }
    
    
    .taskTitle {
      font-size: 1.5rem;
    }

    // .buttonsBlock {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;
      
    //   > * + * {
    //     margin-left: 1rem;
    //   }
    // }

    .textQuestion {
      min-height: 500px;  
      textarea {
          width: 100%;
          height: 15rem;
          resize: none;
          margin: 1rem 0;
          border-color: grey;
          border-radius: .25rem;
          padding: .25rem;
          color: #555555;
          font-size: 1.25rem;
        }
    }
    
    .matchQuestion {
      min-height: 500px;
      > div {
          display: flex;
          flex-direction: column;
          gap: .5rem;
          margin: 1rem 0;
          
          > div {
            width: fit-content;
            border: 2px solid grey;
            padding: 1rem;
            border-radius: .5rem;
            cursor: pointer;
            user-select: none;
            color: #555555;
            font-size: 1.25rem;
          }
        
        }
    }

    .choiceQuestion {
      min-height: 500px;
      > div {
        margin: 1rem 0;
      }
    }

    .multichoiceQuestion {
      min-height: 500px;
      > div {
        margin: 1rem 0;
      }
    }

    .activeQuestion {
      box-shadow: 0 0 1rem;
    }

    .finalWindow {
      min-height: 500px;

      > button {
        margin-top: 5rem;
      }

      > h1 {
        text-align: center;
        margin-top: 3rem;
      }

      > div:nth-child(2) {
        position: absolute;
        top: -1rem;
        left: 0;

        > div:first-child {
          position: absolute;
          width: 200px;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3rem;
          font-weight: 600;
        }
      }

      > button {
        background-color: #4aacc0;
        padding: 1rem 2rem;
        color: white;
        font-size: x-large;
        text-transform: uppercase;
        outline: none;
        border: none;
        cursor: pointer;
        margin: 0 auto;
        display: block;
        transition: .5s;
        
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.fioModal {
  width: 100%;
  max-width: 500px;
  margin: 0 !important;

  > h1 {
    text-align: center;
  }
}

.typesBlock {
  > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.buttonsBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.textarea {
  width: 70% !important;
  
  textarea {
    height: 5rem;
    resize: none;
  }
}

.input {
  margin: 0;
}

.questionNumbers {
  
  display: flex;
  flex-wrap: wrap;
  
  div {
    width: 30px;
    height: 30px;
    margin: .2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    color: grey;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s;

    &:hover {
      transform: rotate(10deg);
    }
  }

  .passedQuestion {
    color: #3497ab;
    border-color: #3497ab;
    background-color: #f3f3f3;
  }
}

.wrongQuestionsBlock {
  margin-top: 1rem;

  > h2 {
    text-align: center;
    cursor: pointer;
    > svg {
      transition: .5s;
      margin-bottom: -.5rem;
      margin-left: 1rem;
    }
  }

  > ul {
    transition: .2s;
    list-style: none;
    overflow: auto;

    li {

      padding-top: 1rem;
  
      > p {
        font-weight: 600;
      }

      > div:nth-child(3) {
        padding: 1rem;
        background-color: #b5daf7;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    > button {
      float: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .modalWrap {
    padding: .5rem !important;
    > .modal {
      > div {
        padding: 0;
      }
    }
  }
  .buttonsBlock {
    > button {
      width: 100%;
    }
  }
}