.wrapper {

  max-width: 1140px;
  margin: 0 auto;
  padding: 3rem 2rem 0;

  > h2 {
    text-transform: uppercase;
    color: rgb(87, 87, 87);
  }

  > * + * {
    margin-top: 1rem;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  svg {
    color: rgb(87, 87, 87);
  }
  
  p {
    color: rgb(153 153 153);
  }

}