

.wrapper {

  max-width: 1140px;
  margin: 0 auto;
  padding: 3rem 2rem 0;

    > div {
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
      }

      button {
        background-color: black;
        padding: 1rem 2rem;
        color: white;
        font-size: x-large;
        text-transform: uppercase;
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        &:last-child {
          background-color: #7c00cd;
        }

        &:hover {
          color: #a8a8a8;
        }

        > svg {
          margin-right: .5rem;
        }
    }
  }

  > ol {
    margin-top: 3rem;

    > li {
      font-size: x-large;
      padding: .5rem;
      cursor: pointer;
      
      > div {
        display: flex;
        
        > p {
          flex-basis: 95%;
        }
        
        > svg {
          margin: auto;
          opacity: 0;
          transition: .5s;
          
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      
      &:hover {
        background-color: #c0c0c01c;
        
        svg {
          opacity: 1;
        }
      }
    }
  }
}