.wrapper {
  
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 25vw;
    width: 30vw;
    color: white;
    padding-top: 5rem;

    > p {

      &:first-child {
        animation: appearFromLeft 1s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        opacity: 0;
      }
      
      &:last-child {
        margin-top: 3rem;
        float: right;
        text-align: right;
        animation: appearFromRight 1s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
        opacity: 0;
      }
    }
  }  

  > img {
    width: 100%;
  }
}




@keyframes appearFromRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes appearFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}




