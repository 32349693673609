

.wrapper {

  > div {
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    box-shadow: 0 0 10px #2b2d2f;
    position: sticky;
    top: 82px;
    z-index: 1;
    background-color: #fff;
    
    > h1 {
      font-weight: 400;
      letter-spacing: 2px;
      font-size: 30px;
    }
  }


  > h1 {
    text-align: center;
  }
}

.list {
  width: 1240px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 2rem auto 0;
  gap: 20px;

  > li {
    width: 400px;
    height: 300px;
    position: relative;
    cursor: pointer;
    transition: .2s;
    display: flex;

    > a {

      width: 100%;
      height: 100%;

      img:first-child {
        position: absolute;
        top: .5rem;
        right: .5rem;
      }
  
      div {
        position: absolute;
        bottom: 15px;
        width: 100%;
        height: 100px;
        background-color: #000000a1;
        color: white;
        padding: .8rem;
        overflow: hidden;
      }
  
    }
    &:hover {
      opacity: .8;
    }
  }
}

@media (max-width: 1240px) {
  .list {
    width: 820px;
  }
}

@media (max-width: 820px) {
  .wrapper {

    > div {
      > h1 {
        font-size: 16px;
      }
    }
  }
  
  .list {
    width: 100%;
    > li {
    background-size: 100%;
    width: 100%;
    }
  }
}