.wrapper {
    margin: .5rem;
}

.wrapper :hover {
    cursor: pointer;
}

.checkbox {
    display: flex;
    align-items: center;
    user-select: none;
    color: #000;
    font-size: 14px;
}

.checkbox input {
    display: none;
}

.checkbox div {
    flex-basis: 35px;
}

.checkbox i {
    border: 2px solid grey;
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 4;
}

.fakeBoxActive {
    background-color: grey;
}

.fakeBoxActive::after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    z-index: 5;
}

.checkbox input:disabled + .fakeBox {
    opacity: 0.5;
}

.icon {
    width: 24px;
    height: 24px;
}

.span {
    display: block;
    flex-basis: calc(100% - 35px);
    margin-top: 1px;
    font-size: 1rem;
}