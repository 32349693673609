

.modal {

  max-width: 800px;
  width: 100%;
  > form {

    > h2 {
      text-align: center;
    }

    > * + * {
      margin: 1rem 0 0;
    }
    
    > .buttonsBlock {
      display: flex;
      justify-content: center;
    }

    > div {

      position: relative;

      > p {
        padding-bottom: .1rem;
        font-weight: 600;
        font-size: .9rem;
      }
      > input[type='number'] {
        font-size: 1.2rem;
        width: 50px;
      }
      > p:nth-child(3) {
        position: absolute;
        top: 100%;
        color: red;
        font-weight: 400;
        font-size: .8rem;
      }
    }
  }
}

.quill {

  > div {
    border: 1px solid grey !important;

    &:first-child {
      border-bottom: none !important;
    }
  }
}

.textarea  textarea {
  height: 10rem;
  resize: none;
}