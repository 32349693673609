.button {
    font-size: 1rem;
    background-color: #4aacc0;
    color: #fff;
    border-radius: 0rem;
    position: relative;
    z-index: 0;
    overflow: hidden;
    border: none;
    transition: all 0.3s;
    padding: .5rem 2rem;
    outline: none;
    height: 36px;
    outline: none;
}

.button:hover {
    transform: translateY(-2px);
    cursor: pointer;
}

.danger {
    background-color: red;
    color: #fff;
}