


.wrapper {

  max-width: 300px;
  margin: 0 auto;
  padding-top: 13%;

  > form {

    > * {
      margin: 1rem 0 0;
    }

    > button {
      font-size: 1rem;
      background-color: black;
      color: #fff;
      border-radius: 0rem;
      border: none;
      transition: all 0.3s;
      padding: .5rem 2rem;
      outline: none;
      height: 36px;
      float: right;
      cursor: pointer;
      letter-spacing: 1px;

      &:hover {
        color: #a8a8a8;
      }
    }
  }
}