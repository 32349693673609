.wrapper {

  margin: 0 auto;
  padding: 3rem 2rem 0;


  > h2 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  > table {
    
    border-collapse: collapse;

    tbody tr:hover {
      cursor: pointer;
      background-color: rgb(240, 240, 240);
    }

    th, td {
      padding: 1rem .1rem;
    }

    td:first-child {
      font-weight: 600;
    }

    td:nth-child(n + 3) {
      text-align: center;
    }
  }

}